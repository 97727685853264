<template>
  <th-middle-page-wrapper>
    <div class="mb-2 text-base font-bold">
      {{ $t('pages.auth.reset_password.headline') }}
    </div>

    <div class="mb-8 text-sm" :class="{ italic: !isWhiteLabel }">
      {{ $t('pages.auth.reset_password.description') }}
    </div>

    <el-form
      ref="form"
      :model="form"
      :rules="rules"
      @submit.prevent="handleNewPassord"
    >
      <!-- Password -->
      <el-form-item
        prop="password"
        :label="$t('pages.auth.reset_password.password.placeholder')"
      >
        <el-input
          id="password"
          v-model="form.password"
          placeholder="········"
          type="password"
          spellcheck="false"
          auto-complete="new-password"
        />
      </el-form-item>

      <!-- Repeat password -->
      <el-form-item
        prop="passwordRepeat"
        :label="$t('pages.auth.reset_password.password_repeat.placeholder')"
      >
        <el-input
          id="passwordRepeat"
          v-model="form.passwordRepeat"
          placeholder="········"
          type="password"
          spellcheck="false"
          auto-complete="new-password"
        />
      </el-form-item>

      <!-- Set password -->
      <el-button type="primary" native-type="submit" class="w-full mt-4">
        {{ $t('pages.auth.reset_password.submit') }}
      </el-button>
    </el-form>

    <!-- Login -->
    <div class="flex justify-end text-xs mt-4">
      <router-link to="/login" class="text-th-secondary">
        {{ $t('pages.auth.reset_password.back') }}
      </router-link>
    </div>
  </th-middle-page-wrapper>
</template>

<script>
import ThMiddlePageWrapper from '@components/middle-page-wrapper'
import auth from '@/plugins/auth'
import { isUnifiedCommerce } from '@/constants'

export default {
  components: {
    ThMiddlePageWrapper
  },

  data() {
    return {
      form: {
        password: '',
        passwordRepeat: '',
        password_reset_id: this.$route.params.password_reset_id
      },
      rules: {
        password: [
          {
            required: true,
            message: this.$t('pages.auth.login.password.validation.required'),
            trigger: 'change'
          },
          {
            min: 5,
            message: this.$t('pages.auth.login.password.validation.length'),
            trigger: 'change'
          }
        ],
        passwordRepeat: [
          {
            required: true,
            message: this.$t('pages.auth.login.password.validation.required'),
            trigger: 'change'
          },
          {
            min: 5,
            message: this.$t('pages.auth.login.password.validation.length'),
            trigger: 'change'
          },
          {
            validator: (rule, value, callback) => {
              if (this.form.password !== this.form.passwordRepeat) {
                return callback(
                  new Error(
                    this.$t('pages.auth.reset_password.errors.does-not-match')
                  )
                )
              } else {
                callback()
              }
            },
            trigger: ['blur', 'change']
          }
        ]
      }
    }
  },

  computed: {
    isWhiteLabel() {
      return isUnifiedCommerce()
    }
  },

  methods: {
    async validate() {
      return new Promise((resolve) => {
        this.$refs.form.validate(resolve)
      })
    },
    async handleNewPassord() {
      if (!(await this.validate())) return

      this.loading = true
      try {
        await auth.newPassword({
          password: this.form.password,
          password_reset_id: this.form.password_reset_id
        })

        // Show success
        this.$message({
          type: 'success',
          message: this.$t('pages.auth.reset_password.success_message')
        })

        // Navigate back
        this.$router.push({ path: '/login' })
      } catch (err) {
        this.$logException(err, {
          message: err.message
        })
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
